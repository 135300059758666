import { createStore } from 'vuex';

import modules from './modules';

const store = createStore({
  modules,
  // // Enable strict mode in development to get a warning
  // // when mutating state outside of a mutation.
  // // https://vuex.vuejs.org/guide/strict.html
  // strict: process.env.NODE_ENV !== 'production',
  state: {
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {},
  getters: {},
});

export default store;

