export function configureFakeBackend() {

    let realFetch = window.fetch;
    window.fetch = function (url, opts) {

        return new Promise((resolve) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                // pass through any requests
                realFetch(url, opts).then(response => resolve(response))

            }, 500);
        });
    }
}

