import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      title: "Log in",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home"
              }
            : {
                ...routeFrom
              }
        );
      }
    }
  },
  {
    path: "/",
    name: "home",
    meta: { title: "Home", authRequired: true },
    component: () => import("../views/pages/home")
  },
  {
    path: "/home",
    name: "homepage",
    meta: { title: "Home", authRequired: true },
    component: () => import("../views/pages/home")
  },
  {
    path: "/configure-deposit",
    name: "configure_deposit",
    meta: { title: "Configure Deposit", authRequired: true },
    component: () => import("../views/pages/ConfigureDeposit")
  },
  {
    path: "/urgencies",
    name: "urgencies",
    meta: { title: "Urgencies", authRequired: true },
    component: () => import("../views/pages/urgencies")
  },
  {
    path: "/gra-report",
    name: "GRA Report",
    meta: { title: "GRA Report", authRequired: true },
    component: () => import("../views/pages/GRAReport")
  },
  {
    path: "/logistics-spend",
    name: "Logistics Spend",
    meta: { title: "Logistics Spend", authRequired: true },
    component: () => import("../views/pages/LogisticsSpend")
  },
  {
    path: "/po-upload",
    name: "po_upload",
    meta: { title: "PO Upload", authRequired: true },
    component: () => import("../views/pages/POUpload")
  },
  {
    path: "/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/pages/orders/groupings")
  },
  {
    path: "/purchase_orders",
    name: "purchase_orders",
    meta: { title: "Purchase Orders", authRequired: true },
    component: () => import("../views/pages/purchase_orders/groupings")
  },
  {
    path: "/orders/pending/all/:wh",
    name: "pending",
    meta: { title: "Pending", authRequired: true },
    props: true,
    component: () => import("../views/pages/orders/shipping/groupings")
  },

  {
    path: "/quotations/open",
    name: "quotations",
    meta: {title: "Quotations", authRequired: true},
    props: true,
    component: () => import("../views/pages/quotations/QuotationsView")
  },
  {
    path: "/bookings/:direction/:mode",
    name: "bookings",
    meta: {title: "Bookings", authRequired: true},
    props: true,
    component: () => import("../views/pages/bookings/BookingsView")
  },
  {
    path: "/shipments/imports/air",
    name: "shipments",
    meta: {title: "Shipments", authRequired: true},
    props: true,
    component: () => import("../views/pages/shipments/ShipmentsView")
  }, 
  {
    path: "/brokerage/imports/ocean",
    name: "brokerage",
    meta: {title: "Brokerage", authRequired: true},
    props: true,
    component: () => import("../views/pages/brokerage/BrokerageView")
  }, 
  {
    path: "/maximo/imports/ocean",
    name: "atlantic",
    meta: {title: "Atlantic Warehouse", authRequired: true},
    props: true,
    component: () => import("../views/pages/maximo/ShipmentsView")
  }, 
  {
    path: "/invoicing",
    name: "invoices",
    meta: {title: "Invoicing", authRequired: true},
    props: true,
    component: () => import("../views/pages/invoicing/InvoiceView")
  },
  {
    path: "/account/login",
    name: "login",
    meta: { title: "Login", authRequired: true },
    component: () => import("../views/pages/account/login")
  }
]
